



.vessel-table tr:nth-child(2n) td {
    background-color: #eee;
}


.vessel-table .ant-table-thead > tr:not(:last-child) > th:not(:first-child) {
    border-left: grey 1px solid !important;
}

/*.vessel-table .ant-table-thead > tr:not(:first-child) > th:not(:first-child):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(5)):not(:nth-child(6)):not(:nth-child(8)):not(:nth-child(9)):not(:nth-child(11)) {
    border-left: grey 1px solid !important;
}*/

.vessel-table .ant-table-thead > tr:nth-child(2) > th:nth-child(2) {
    border-left: grey 1px solid !important;
}

.vessel-table .ant-table-thead > tr:nth-child(2) > th:nth-child(4) {
    border-left: grey 1px solid !important;
}

.vessel-table .ant-table-thead > tr:nth-child(2) > th:nth-child(10) {
    border-left: grey 1px solid !important;
}

.vessel-table .ant-table-thead > tr:nth-child(2) > th:nth-child(12) {
    border-left: grey 1px solid !important;
}

.vessel-table .ant-table-thead > tr:nth-child(2) > th:nth-child(14) {
    border-left: grey 1px solid !important;
}

.vessel-table .ant-table-tbody td:nth-child(2) {
    border-left: grey 1px solid !important;
}

.vessel-table .ant-table-tbody td:nth-child(4) {
    border-left: grey 1px solid !important;
}

.vessel-table .ant-table-tbody td:nth-child(10) {
    border-left: grey 1px solid !important;
}

.vessel-table .ant-table-tbody td:nth-child(12) {
    border-left: grey 1px solid !important;
}

.vessel-table .ant-table-tbody td:nth-child(14) {
    border-left: grey 1px solid !important;
}


/*.vessel-table .ant-table-thead > tr:not(:last-child) > th:not(:first-child):not(:nth-child(2)):not(:nth-child(3)):not(:last-child) {
    border-bottom: grey 1px solid !important;
}*/