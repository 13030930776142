.debug {
    .map {
        width: 100%;
        height: 400px;
    }

    .ol-popup {
        position: absolute;
        background-color: white;
        box-shadow: 0 1px 4px rgba(0,0,0,0.2);
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #cccccc;
        bottom: 12px;
        left: -50px;
        min-width: 280px;
    }

    .ol-popup:after, .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .ol-popup:after {
        border-top-color: white;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
    }

    .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
    }

    .debug-right-panel-summary .ant-descriptions-item-label {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .debug-right-panel-summary .ant-descriptions-item-content {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .debug-info-box .ant-descriptions-item-label {
        padding: 0px 16px !important;
    }

    .debug-info-box .ant-descriptions-item-content {
        padding: 0px 16px !important;
    }

    .track-rect {
        display: inline-block;
        width: 22px;
        height: 11px;
    }

    .point-table {
        .ant-table-body {
            overflow-y: auto !important;
        }

        .ant-table-tbody > tr > td {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

    .point-details {

        .ant-table-tbody > tr > td {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

    .track-table {

        .ant-table-tbody > tr > td {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

    .ant-tabs .ant-space-item {
        width: 100% !important;
    }

    .highlighted-row {
        z-index: 11;
        background-color: #d7e1ec !important;
    }

    .point-details tr:nth-child(2n) td {
        background-color: rgba(238, 238, 238, 0.25);
    }

    .track-table tr:nth-child(2n) td {
        background-color: rgba(238, 238, 238, 0.25);
    }

    .highlight-bottom-border > td {
        border-bottom: solid 1px #95a3b2 !important
    }

    .highlight-top-border > td {
        border-top: solid 1px #95a3b2 !important;
    }
}
