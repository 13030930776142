/* global styling */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.ant-tabs-nav {
  margin-left: 12px !important;
  margin-bottom: 0px !important;
}
.ant-tabs-nav-list {
  transform: unset !important;
}
.highlight-row {
  background-color: rgba(186, 189, 218, 0.35);
}
.debug .map {
  width: 100%;
  height: 400px;
}
.debug .ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}
.debug .ol-popup:after,
.debug .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.debug .ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.debug .ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.debug .debug-right-panel-summary .ant-descriptions-item-label {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.debug .debug-right-panel-summary .ant-descriptions-item-content {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.debug .debug-info-box .ant-descriptions-item-label {
  padding: 0px 16px !important;
}
.debug .debug-info-box .ant-descriptions-item-content {
  padding: 0px 16px !important;
}
.debug .track-rect {
  display: inline-block;
  width: 22px;
  height: 11px;
}
.debug .point-table .ant-table-body {
  overflow-y: auto !important;
}
.debug .point-table .ant-table-tbody > tr > td {
  padding-top: 0px;
  padding-bottom: 0px;
}
.debug .point-details .ant-table-tbody > tr > td {
  padding-top: 0px;
  padding-bottom: 0px;
}
.debug .track-table .ant-table-tbody > tr > td {
  padding-top: 0px;
  padding-bottom: 0px;
}
.debug .ant-tabs .ant-space-item {
  width: 100% !important;
}
.debug .highlighted-row {
  z-index: 11;
  background-color: #d7e1ec !important;
}
.debug .point-details tr:nth-child(2n) td {
  background-color: rgba(238, 238, 238, 0.25);
}
.debug .track-table tr:nth-child(2n) td {
  background-color: rgba(238, 238, 238, 0.25);
}
.debug .highlight-bottom-border > td {
  border-bottom: solid 1px #95a3b2 !important;
}
.debug .highlight-top-border > td {
  border-top: solid 1px #95a3b2 !important;
}
.override {
  padding: 12px;
}
.override .ant-table {
  max-width: 1400px;
}
.vessel-table tr:nth-child(2n) td {
  background-color: #eee;
}
.vessel-table .ant-table-thead > tr:not(:last-child) > th:not(:first-child) {
  border-left: grey 1px solid !important;
}
/*.vessel-table .ant-table-thead > tr:not(:first-child) > th:not(:first-child):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(5)):not(:nth-child(6)):not(:nth-child(8)):not(:nth-child(9)):not(:nth-child(11)) {
    border-left: grey 1px solid !important;
}*/
.vessel-table .ant-table-thead > tr:nth-child(2) > th:nth-child(2) {
  border-left: grey 1px solid !important;
}
.vessel-table .ant-table-thead > tr:nth-child(2) > th:nth-child(4) {
  border-left: grey 1px solid !important;
}
.vessel-table .ant-table-thead > tr:nth-child(2) > th:nth-child(10) {
  border-left: grey 1px solid !important;
}
.vessel-table .ant-table-thead > tr:nth-child(2) > th:nth-child(12) {
  border-left: grey 1px solid !important;
}
.vessel-table .ant-table-thead > tr:nth-child(2) > th:nth-child(14) {
  border-left: grey 1px solid !important;
}
.vessel-table .ant-table-tbody td:nth-child(2) {
  border-left: grey 1px solid !important;
}
.vessel-table .ant-table-tbody td:nth-child(4) {
  border-left: grey 1px solid !important;
}
.vessel-table .ant-table-tbody td:nth-child(10) {
  border-left: grey 1px solid !important;
}
.vessel-table .ant-table-tbody td:nth-child(12) {
  border-left: grey 1px solid !important;
}
.vessel-table .ant-table-tbody td:nth-child(14) {
  border-left: grey 1px solid !important;
}
/*.vessel-table .ant-table-thead > tr:not(:last-child) > th:not(:first-child):not(:nth-child(2)):not(:nth-child(3)):not(:last-child) {
    border-bottom: grey 1px solid !important;
}*/
